<template>
    <v-container fluid>
        <v-layout wrap>
            <v-flex fluid>
                <v-card outlined>
                    <v-card>
                        <h3>Faturamento</h3>
                        <v-col>
                            <v-row cols="12" sm="6">
                                <v-text-field label="Data Inicial" outlined dense type="date"></v-text-field>
                                <v-text-field label="Data Final" outlined dense type="date"></v-text-field>
                            </v-row>
                            <v-row>
                                <v-card-text>
                                    <v-data-table :headers="headers" :items="desserts"
                                        class="elevation-3"></v-data-table>
                                </v-card-text>
                            </v-row>

                        </v-col>
                    </v-card>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: "faturamento_equipe_multi.vue",
    desserts: [],
    desserts2: [],
    data: () => ({
        value: 0,
        headers: [
            { text: 'Paciente', align: 'start', sortable: true, value: 'paciente' },
            { text: 'QT Liberada', align: 'start', sortable: true, value: 'qt_liberada' },
            { text: 'QT Validada', align: 'start', sortable: true, value: 'qt_validada' },
            { text: 'QT Rejeitada', align: 'start', sortable: true, value: 'qt_rejeitada' },
            { text: 'R$ da Produção', align: 'start', sortable: true, value: 'valor_producao' },
        ],
    }),
    created() {
        this.initialize()
    },
    methods: {
        initialize() {
            this.desserts = [
                {
                    paciente: 'Mauro Teste de Sousa',
                    qt_liberada: '20',
                    qt_validade: '10',
                    qt_rejeitada: '10',
                    status: 'R$ 300,00',
                    
                }

            ]
        },
    }
}
</script>
